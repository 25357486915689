.why {
  padding-bottom: 179px;
  @include media-3xl {
    padding-bottom: 198px;
  }
  @include media-4xl {
    padding-bottom: 250px;
  }
  &:before {
    content: "";
    position: absolute;
    transform: scale(-1);
    bottom: -442px;
    right: 0;
    width: 387px;
    height: 1000px;
    background-repeat: repeat;
    background-position: center;
    background-size: contain;
    background-image: url('../img/bg/dots.webp');
		pointer-events: none;
  }
  .title {
    margin-bottom: 50px;
    position: relative;
    @include media-lg {
      margin-bottom: 60px;
    }
    @include media-xxl {
      margin-bottom: 96px;
    }
    @include media-3xl {
      margin-bottom: 106px;
    }
    @include media-4xl {
      margin-bottom: 130px;
    }
    &:before {
      @include media-lg {
        content: "";
        position: absolute;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-image: url('../img/bg/iron.webp');
        top: -90px;
        left: 494px;
        width: 500px;
        height: 400px;
        pointer-events: none;
      }
      @include media-xl {
        top: -140px;
        left: 494px;
        width: 740px;
        height: 620px;
      }
      @include media-xxl {
        top: -250px;
        left: 574px;
        width: 740px;
        height: 720px;
      }
      @include media-3xl {
        top: -241px;
        left: 643px;
        width: 871px;
        height: 740px;
      }
      @include media-4xl {
        top: -290px;
        left: 794px;
        width: 1000px;
        height: 900px;
      }
    }
  }
  &__img {
    display: flex;
    justify-content: flex-end;
    @include media-lg {
      transform: translateX(25px);
    }
    img {
      max-width: 100%;
      @include media-lg {
        max-width: initial;
        width: 122%;
      }
    }
  }
  &-acc {
    margin-top: 100px;
    padding-bottom: 15px;
    border-bottom: 2px solid #C9C9C9;
    @include media-lg {
      margin-top: 74px;
    }
    @include media-3xl {
      margin-top: 78px;
    }
    @include media-4xl {
      margin-top: 100px;
    }
    &__title {
      position: relative;
      padding-top: 20px;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 20px;
      padding-right: 30px;
      font-weight: 600;
      line-height: 1.2;
      @include media-lg {
        font-size: 17px;
        line-height: 1.1;
      }
      @include media-3xl {
        font-size: 20px;
        padding-top: 24px;
      }
      @include media-4xl {
        padding-top: 20px;
        font-size: 24px;
        line-height: 40px;
      }
      &:not(:first-child) {
        margin-top: 18px;
        border-top: 2px solid #C9C9C9;
      }
      &:after {
        content: "";
        position: absolute;
        top: 20px;
        right: 0;
        width: 22px;
        height: 22px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22' fill='none'%3e%3cpath d='M22 11C22 11.1862 21.926 11.3648 21.7944 11.4965C21.6627 11.6282 21.4841 11.7021 21.2979 11.7021H11.7021V21.2979C11.7021 21.4841 11.6282 21.6627 11.4965 21.7944C11.3648 21.926 11.1862 22 11 22C10.8138 22 10.6352 21.926 10.5035 21.7944C10.3718 21.6627 10.2979 21.4841 10.2979 21.2979V11.7021H0.702128C0.515912 11.7021 0.337323 11.6282 0.205648 11.4965C0.0739739 11.3648 0 11.1862 0 11C0 10.8138 0.0739739 10.6352 0.205648 10.5035C0.337323 10.3718 0.515912 10.2979 0.702128 10.2979H10.2979V0.702128C10.2979 0.515912 10.3718 0.337323 10.5035 0.205648C10.6352 0.0739739 10.8138 0 11 0C11.1862 0 11.3648 0.0739739 11.4965 0.205648C11.6282 0.337323 11.7021 0.515912 11.7021 0.702128V10.2979H21.2979C21.4841 10.2979 21.6627 10.3718 21.7944 10.5035C21.926 10.6352 22 10.8138 22 11Z' fill='%23246EB9'/%3e%3c/svg%3e");
        transition: transform 0.3s ease-in-out;
        @include media-lg {
          top: 18px;
        }
        @include media-3xl {

        }
        @include media-4xl {
          top: 29px;
        }
      }
      &.is-opened:after {
        transform: rotate(135deg);
      }
    }
    &__content {
      margin-top: 10px;
      padding-right: 30px;
    }
  }
  .divider {
    bottom: 80px;
    transform: translate(-50%, 0) rotate(8.46deg);
    @include media-xxl {
      bottom: 51px;
    }
    @include media-3xl {

    }
    @include media-4xl {
      bottom: 80px;
    }
  }
}