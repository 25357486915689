.home {
  padding-top: 100px;
  padding-bottom: 40px;
  @include media-md {
    padding-top: 252px;
    padding-bottom: 0;
  }
  @include media-lg {
    padding-top: 82px;
    padding-bottom: 145px;
  }
  @include media-xl {
    padding-top: 104px;
  }
  @include media-3xl {
    padding-top: 77px;
  }
  @include media-4xl {
    padding-top: 90px;
    padding-bottom: 178px;
  }
  &:before {
    content: "";
    position: absolute;
    top: 22px;
    left: 0;
    width: 387px;
    height: 1000px;
    background-repeat: repeat;
    background-position: center;
    background-size: contain;
    background-image: url('../img/bg/dots.webp');
		pointer-events: none;
  }
  &__title {
    max-width: 350px;
    margin: 0 auto;
    @include media-sm {
      max-width: 560px;
    }
    @include media-lg {
      max-width: none;
    }
  }
  &__description {
    max-width: 320px;
    font-size: 18px;
    letter-spacing: 1.2px;
    margin: 0 0 0 32%;
    @include media-lg {
      max-width: 308px;
      font-size: 24px;
      line-height: 28px;
      margin: 0;
    }
    @include media-xl {
      max-width: 240px;
      font-size: 19px;
      line-height: 1;
      margin: 0;
    }
    @include media-3xl {
      max-width: 268px;
      font-size: 21px;
      line-height: 1.2;
    }
    @include media-4xl {
      max-width: 308px;
      font-size: 24px;
      line-height: 28px;
      margin: 0;
    }
  }
  &__text {
    font-weight: 300;
    margin-bottom: 20px;
    @include media-xl {
      margin-top: -79px;
    }
    @include media-3xl {
      margin-top: -87px;
    }
    @include media-4xl {
      margin-top: -90px;
    }
  }
  &__location {
    display: flex;
    align-items: center;
    color: #246EB9;
    font-weight: 500;
    .icon {
      width: 18px;
      height: 21px;
      margin-right: 11px;
      fill: #246EB9;
    }
  }
  &__btn {
    max-width: 350px;
    margin: 47px auto 0;
    text-align: center;
    @include media-xl {
      margin: 33px auto 0;
    }
    @include media-4xl {
      margin: 50px auto 0;
    }
    .btn {
      padding: 15px 35px;
      @include media-xl {
        padding: 23px 26px;
        font-size: 15px;
        line-height: 1;
      }
      @include media-3xl {
        padding: 25px 31px;
        font-size: 17px;
      }
      @include media-4xl {
        font-size: 20px;
        padding: 34px 35px;
      }
    }
  }
  &__icons {
    display: flex;
    margin-top: 105px;
    @include media-lg {
      margin-top: 0;
      margin-bottom: 75px;
    }
    @include media-xl {
      margin-top: 80px;
      margin-bottom: 0px;
    }
  }
  &__icon {
    display: inline-flex;
    .icon {
      fill: #000;
      width: 20px;
      height: 20px;
      fill: #1F1F1F;
      @include media-lg {
        width: 25px;
        height: 25px;
      }
      @include media-3xl {
        width: 30px;
        height: 30px;
      }
    }
    &:hover {
      .icon {
        fill: #FF4986;
      }
    }
    &+.home__icon {
      margin-left: 10px;
      @include media-3xl {
        margin-left: 15px;
      }
    }
  }
}
