.advantages {
  margin-top: -100px;
  padding-bottom: 200px;
  @include media-lg {
    padding-bottom: 200px;
  }
  @include media-xl {
    padding-bottom: 259px;
  }
  @include media-3xl {
    padding-bottom: 307px;
  }
  @include media-4xl {
    padding-bottom: 359px;
  }
  &:before {
    content: "";
    position: absolute;
    bottom: 225px;
    transform: scale(-1);
    right: 0;
    width: 387px;
    height: 1000px;
    background-repeat: repeat;
    background-position: center;
    background-size: contain;
    background-image: url('../img/bg/dots.webp');
		pointer-events: none;
  }
  .title {
    font-size: 40px;
    position: relative;
    z-index: 1;
    @include media-sm {
      font-size: 56px;
    }
    @include media-xl {
      font-size: 67px;
    }
    @include media-3xl {
      font-size: 77px;
    }
    @include media-4xl {
      font-size: 90px;
      line-height: 110px;
    }
    &:before {
      content: "";
      position: absolute;
      bottom: 140px;
      right: -6%;
      width: 360px;
      height: 431px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-image: url('../img/bg/spider-red.webp');
      z-index: -1;
      pointer-events: none;
      @include media-sm {
        bottom: 40px;
        right: 0%;
        width: 530px;
        height: 431px;
      }
      @include media-lg {
        content: none;
      }
    }
    .accent-text {
      font-size: 70px;
      @include media-sm {
        font-size: 86px;
        line-height: 75px;
      }
      @include media-xl {
        font-size: 110px;
        line-height: 50px;
      }
      @include media-3xl {
        font-size: 124px;
        line-height: 50px;
      }
      @include media-4xl {
        font-size: 140px;
        line-height: 80px;
      }
    }
  }
  &__btn {
    text-align: center;
  }
  .description {
    margin-top: 15px;
    margin-bottom: 46px;
    max-width: 360px;
    @include media-xxl {
      margin-top: 20px;
    }
    @include media-3xl {
      margin-top: 5px;
      max-width: 280px;
    }
    @include media-4xl {
      margin-top: 6px;
      max-width: 360px;
    }
  }
  .text {
    max-width: 360px;
    @include media-3xl {
      max-width: 300px;
    }
    @include media-3xl {
      max-width: 360px;
    }
  }
  &__img {
    display: flex;
    justify-content: flex-end;
    margin-top: 87px;
    margin-bottom: 300px;
    position: relative;
    @include media-md {
      margin-bottom: 80px;
    }
    @include media-lg {
      margin-bottom: 227px;
      transform: translateX(31px);
    }
    @include media-xxl {
      margin-bottom: 156px;
    }
    @include media-3xl {
      margin-bottom: 196px;
    }
    @include media-4xl {
      margin-bottom: 227px;
    }
    &:before {
      content: "";
      position: absolute;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-image: url('../img/bg/halk.webp');
      top: auto;
      top: 107%;
      right: 0;
      width: 100%;
      height: 280px;
      pointer-events: none;
      @include media-md {
        top: 116%;
        width: 300px
      }
      @include media-lg {
        content: none
      }
    }
    img {
      max-width: 100%;
      @include media-lg {
        max-width: initial;
        width: 122%;
      }
    }
  }
  .divider {
    bottom: 70px;
    transform: translate(-50%, 0) rotate(8.46deg);
    @include media-lg {
      bottom: 73px;
    }
    @include media-xl {
      bottom: 73px;
    }
    @include media-xxl {
      bottom: 181px;
    }
    @include media-3xl {
      bottom: 212px;
    }
    @include media-4xl {
      bottom: 263px;
    }
  }
  &__btn {
    .btn {
      padding: 15px 35px;
      @include media-xl {
        padding: 23px 26px;
        font-size: 15px;
        line-height: 1;
      }
      @include media-3xl {
        padding: 25px 31px;
        font-size: 17px;
      }
      @include media-4xl {
        font-size: 20px;
        padding: 34px 35px;
      }
    }
  }
}
.counter {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 95px;
  position: relative;
  @include media-md {
    margin-bottom: 35px;
  }
  @include media-lg {
    margin-bottom: 95px;
    margin-top: 0;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  @include media-xxl {
    margin-bottom: 65px;
  }
  &:before {
    @include media-lg {
      content: "";
      position: absolute;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-image: url('../img/bg/halk.webp');
      top: auto;
      bottom: 229px;
      right: -164px;
      width: 382px;
      height: 405px;
      pointer-events: none;
    }
    @include media-lg {
      bottom: 99px;
      right: -164px;
      width: 352px;
      height: 375px;
    }
    @include media-xl {
      bottom: 69px;
      right: -324px;
      width: 482px;
      height: 545px;
    }
    @include media-xxl {
      bottom: -96px;
      right: -528px;
      width: 692px;
      height: 725px;
    }
    @include media-3xl {
      bottom: -146px;
      right: -598px;
      width: 782px;
      height: 845px;
    }
    @include media-4xl {
      bottom: -126px;
      right: -714px;
      width: 932px;
      height: 995px;
    }
  }
  &__item {
    width: 100%;
    max-width: 300px;
    text-align: center;
    font-size: 24px;
    line-height: 1.2;
    font-weight: 400;
    margin-bottom: 30px;
    @include media-lg {
      font-size: 20px;
      line-height: 23px;
      max-width: 210px;
      margin-bottom: 0;
    }
    @include media-3xl {
      font-size: 22px;
      margin-bottom: 21px;
    }
    @include media-4xl {
      line-height: 32px;
      font-size: 28px;
      margin-bottom: 0;
    }
    .accent-color {
      font-weight: 500;
      padding-right: 10px;
    }
  }
  &__num {
    color: #246EB9;
    font-size: 70px;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 12px;
    @include media-lg {
      font-size: 69px;
    }
    @include media-3xl {

    }
    @include media-4xl {
      font-size: 90px;
    }
  }
  &__plus {
    font-size: 30px;
    position: relative;
    top: -40px;
    font-weight: 800;
    @include media-lg {
      font-size: 35px;
      top: -37px;
    }
    @include media-3xl {

    }
    @include media-4xl {
      font-size: 50px;
      top: -47px;
    }
  }
  &__text {
    text-align: center;
  }
}