.footer {
  padding: 30px 0;
  background: #246EB9;
  color: #FCFBF8;
  font-size: 15px;
  font-weight: 300;
  line-height: 24px;
  @include media-lg {
    padding: 60px 0;
  }
  @include media-3xl {
    font-size: 17px;
  }
  @include media-4xl {
    font-size: 20px;
    padding: 70px 0;
  }
  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include media-lg {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
  &__item {
    display: flex;
    align-items: center;
  }
  &__link {
    color: #FCFBF8;
  }
  &__site {
    display: block;
    width: 28px;
    height: 18px;
    @include media-4xl {
      width: 35px;
      height: 22px;
    }
  }
  .icon {
    width: 28px;
    height: 18px;
    margin-left: 70px;
    @include media-3xl {
      margin-left: 86px;
    }
    @include media-4xl {
      width: 35px;
      height: 22px;
      margin-left: 99px;
    }
  }
  &-logo {
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
    padding: 30px 0;
    @include media-lg {
      padding: 0 0 0 11%;
    }
    @include media-3xl {
      padding: 0 0 0 7%;
    }
    @include media-4xl {
      padding: 0 0 0 128px
    }
    &__name {
      letter-spacing: 2.5px;
      font-size: 36px;
      margin-bottom: 4px;
      @include media-3xl {
        font-size: 41px;
      }
      @include media-4xl {
        font-size: 50px;
        margin-bottom: 10px;
      }
    }
    &__company {
      font-size: 12px;
      letter-spacing: 2.7px;
      @include media-3xl {
        font-size: 15px;
      }
      @include media-4xl {
        font-size: 18px;
      }
    }
  }
  &__up {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 10;
    width: 36px;
    height: 28px;
    .icon {
      width: 100%;
      height: 100%;
    }
  }
}