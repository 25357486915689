@mixin media-sm {
    @media (min-width: #{576px}) {
        @content;
    }
}
@mixin media-md {
    @media (min-width: #{768px}) {
        @content;
    }
}
@mixin media-lg {
    @media (min-width: #{992px}) {
        @content;
    }
}
@mixin media-xl {
    @media (min-width: #{1200px}) {
        @content;
    }
}
@mixin media-xxl {
    @media (min-width: #{1400px}) {
        @content;
    }
}
@mixin media-3xl {
    @media (min-width: #{1600px}) {
        @content;
    }
}
@mixin media-4xl {
    @media (min-width: #{1850px}) {
        @content;
    }
}

$white: #ffffff;
$bg: #FAF9F8;
// $bg-100: #F0F0F0;
$text: #1F1F1F;
$accent: #FF4986;
// $grey: #5E728D;
// $grey-100: #ECEDEF;
// $danger: #EB6A58;
// $danger-100: #FCE6E2;
// $danger-200: #EF806E;
// $info: #A17601;
// $info-100: rgba(255, 232, 182, 0.60);
// $info-200: #FFE8B6;
// $info-300: #FCC234;
// $border: #F1F2F3;
// $label: #79879B;
// $label-100: #C4C4C4;
// $tooltip: #58667C;
