.packages {
  padding-bottom: 480px;
  @include media-lg {
    padding-bottom: 300px;
  }
  @include media-xl {
    padding-bottom: 298px;
  }
  @include media-3xl {
    padding-bottom: 341px;
  }
  @include media-4xl {
    padding-bottom: 417px;
  }
  &:before {
    content: "";
    position: absolute;
    bottom: 730px;
    left: 0;
    width: 387px;
    height: 1000px;
    background-repeat: repeat;
    background-position: center;
    background-size: contain;
    background-image: url('../img/bg/dots.webp');
		pointer-events: none;
  }
  .title {
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 30px;
    @include media-lg {
      margin-bottom: 67px;
    }
    @include media-3xl {
      margin-bottom: 83px;
    }
    @include media-4xl {
      margin-bottom: 85px;
    }
    &:before {
      @include media-lg {
        content: "";
        position: absolute;
        top: -457px;
        left: -51px;
        width: 431px;
        height: 541px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-image: url('../img/bg/cap.webp');
        pointer-events: none;
      }
      @include media-xl {
        top: -367px;
        left: -73px;
        width: 601px;
        height: 611px;
      }
      @include media-xxl {
        top: -352px;
        left: -53px;
        width: 541px;
        height: 571px;

      }
      @include media-3xl {
        top: -377px;
        left: -70px;
        width: 621px;
        height: 611px;
      }
      @include media-4xl {
        top: -457px;
        left: -73px;
        width: 731px;
        height: 741px;

      }
    }
    &--steps {
      @include media-xxl {
        margin-bottom: 28px;
      }
      @include media-3xl {

      }
      @include media-4xl {
        margin-bottom: 0;
      }
      &:before {
        content: "";
        position: absolute;
        left: auto;
        top: auto;
        bottom: 146px;
        right: 0;
        width: 96%;
        height: 325px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-image: url('../img/bg/cat.webp');
        pointer-events: none;
        @include media-lg {
          bottom: 73px;
          right: -180px;
          width: 471px;
          height: 555px;
        }
        @include media-xl {
          bottom: -118px;
          right: -268px;
          width: 602px;
          height: 660px;
        }
        @include media-3xl {

        }
        @include media-4xl {
          bottom: -127px;
          right: -360px;
          width: 811px;
          height: 865px;
        }
      }
    }
  }
  &-items {
    margin-left: -15px;
    margin-right: -15px;
    width: calc(100% + 30px);
    .slick-dots {
      @include media-xl {
        display: none;
      }
    }
    .slick-track {
      display: flex;
    }
    .slick-slide {
      height: auto;
    }
  }
  &-item {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px 10px;
    border-radius: 40px;
    border: 2px solid #1F1F1F;
    background: #FFF;
    max-width: 427px;
    margin-left: auto;
    margin-right: auto;
    @include media-md {
      padding: 20px;
      max-width: 527px;
    }
    @include media-xl {
      padding: 30px 20px;
    }
    @include media-xxl {
      padding: 39px 37px 45px;
    }
    @include media-3xl {
      padding: 45px 44px 45px;
    }
    @include media-4xl {
      padding: 55px 57px 62px 52px;
    }
    &-wrap {
      padding-right: 15px;
      padding-left: 15px;
    }
    &__name {
      padding-left: 6px;
      margin-bottom: 22px;
      color: #246EB9;
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
      text-transform: uppercase;
      @include media-lg {
        font-size: 27px;
        line-height: 1.1;
      }
      @include media-3xl {
        font-size: 31px;
      }
      @include media-4xl {
        font-size: 36px;
        padding-left: 10px;
        margin-bottom: 33px;
      }
    }
    &__line {
      display: flex;
      flex-direction: column-reverse;
      flex-wrap: wrap;
      align-items: flex-start;
      @include media-lg {
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
      }
      .accent-color {
        margin-bottom: 10px;
        @include media-lg {
          margin-bottom: 0;
        }
      }
    }
    &__group {
      display: flex;
      flex-wrap: wrap;
      &:not(:last-child) {
        margin-bottom: 22px;
      }
    }
    &__options {
      margin-bottom: 10px;
      font-size: 15px;
      @include media-lg {
        margin-bottom: 33px;
      }
      @include media-3xl {
        font-size: 17px;
        margin-bottom: 23px;
      }
      @include media-4xl {
        font-size: 20px;
        margin-bottom: 42px;
      }
      div.accent-color {
        margin-top: -4px;
        margin-bottom: 5px;
        @include media-3xl {
          margin-bottom: 6px;
        }
        @include media-4xl {
          margin-bottom: 9px;
        }
      }
    }
    &__option {
      display: flex;
      white-space: nowrap;
      color: #FFF;
      font-weight: 500;
      line-height: 1.2;
      border-radius: 50px;
      margin-bottom: 6px;
      margin-right: 4px;
      font-size: 15px;
      padding: 5px 7px;
      // @include media-md {
      //   margin-bottom: 8px;
      //   margin-right: 8px;
      //   font-size: 17px;
      //   padding: 10px 15px;
      // }
      @include media-lg {
        font-size: 20px;
        line-height: 24px;
        padding: 14px 19px;
      }
      @include media-xl {
        font-size: 15px;
        line-height: 1.1;
        padding: 10px 12px;
      }
      @include media-3xl {
        font-size: 17px;
        line-height: 1.1;
        padding: 13px 13px;
      }
      @include media-4xl {
        font-size: 20px;
        line-height: 24px;
        padding: 14px 19px;
      }
      &--yellow {
        background: rgba(238, 157, 0, 0.70);
      }
      &--green {
        background: rgba(72, 169, 27, 0.70);
      }
      &--red {
        background: rgba(255, 70, 33, 0.70);
      }
      &--blue {
        background: rgba(36, 110, 185, 0.70);
      }
      &--violet {
        background: rgba(146, 119, 154, 0.70);
      }
    }
    &__description {
      margin-top: auto;
      text-align: right;
      font-size: 18px;
      font-weight: 400;
      line-height: 1.4;
      @include media-lg {
        font-size: 23px;
      }
      @include media-xl {
        font-size: 20px;
        line-height: 1.4;
      }
      @include media-3xl {
        font-size: 23px;
      }
      @include media-4xl {
        font-size: 28px;
        line-height: 40px;
      }
      .accent-color {
        padding-left: 10px;
      }
    }
    &__choices {
      margin-top: 5px;
      margin-bottom: 15px;
      color: #A6A6A6;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.2;
      @include media-md {
        font-size: 15px;
        line-height: 21px;
      }
      @include media-3xl {
        font-size: 16px;
        line-height: 27px;
      }
      @include media-4xl {
        font-size: 20px;
        line-height: 34px;
      }
      .tabs__caption {
        display: flex;
        justify-content: flex-end;
        @include media-xxl {
          margin-bottom: 9px;
        }
        @include media-3xl {
          margin-bottom: 5px;
        }
        @include media-4xl {
          margin-bottom: 0;
        }
      }
      li+li {
        margin-left: 10px;
      }
      li.active, li:hover {
        color: #1F1F1F;
      }
    }
    &__price {
      margin-top: 6px;
      font-size: 36px;
      font-weight: 700;
      line-height: 40px;
      text-transform: uppercase;
      color: #1F1F1F;
      @include media-xxl {
        margin-top: 0;
        font-size: 26px;
        line-height: 27px;
      }
      @include media-3xl {
        font-size: 30px;
      }
      @include media-4xl {
        margin-top: 6px;
        font-size: 36px;
        line-height: 40px;
      }
    }
    &__details {
      color: #585858;
      text-align: right;
      font-size: 15px;
      line-height: 21px;
      font-style: normal;
      font-weight: 400;
      @include media-3xl {
        font-size: 17px;
        line-height: 38px;
      }
      @include media-4xl {
        font-size: 20px;
        line-height: 24px;

      }
      span {
        color: #1F1F1F;
        font-weight: 500;
      }
    }
    &__btn {
      margin-top: 13px;
      @include media-3xl {
        margin-top: 23px;
      }
      @include media-4xl {
        margin-top: 35px;
      }
    }
    .btn {
      min-width: 200px;
    }
  }
  &__description {
    margin-top: 91px;
    margin-bottom: 328px;
    text-align: center;
    @include media-xxl {
      margin-top: 66px;
      margin-bottom: 206px;
    }
    @include media-3xl {
      margin-top: 78px;
      margin-bottom: 232px;
    }
    @include media-4xl {
      margin-top: 100px;
      margin-bottom: 274px;
    }
    .description {
      max-width: 455px;
      margin: 0 auto;
      @include media-3xl {
        max-width: 374px;
      }
      @include media-4xl {
        max-width: 455px;
      }
    }
  }
  &__steps {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @include media-xxl {
      flex-wrap: nowrap;
      margin-top: 120px;
    }
    @include media-3xl {
      margin-top: 150px;
    }
    @include media-4xl {
      margin-top: 205px;
    }
    .slick-list {
      overflow: hidden;
      padding: 100px 0 0;
      @include media-lg {
        overflow: visible;
        padding: 0;
      }
    }
    .slick-dots {
      display: flex;
      @include media-lg {
        display: none;
      }
    }
  }
  &-step {
    position: relative;
    width: 100%;
    // max-width: 172px;
    text-align: center;
    margin: 0 50px 110px;
    @include media-sm {
      margin: 0 25px 100px;
    }
    @include media-md {
      margin: 0 35px 160px;
    }
    @include media-lg {
      margin: 0 20px 50px;
    }
    @include media-xl {
      margin: 0 40px;
    }
    @include media-xxl {
      margin: 0 85px;
    }
    @include media-3xl {

    }
    @include media-4xl {
      margin: 0 120px;
    }
    &__name {
      position: relative;
      max-width: 202px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 17px;
      font-weight: 400;
      white-space: nowrap;
      font-size: 48px;
      line-height: 1;
      @include media-xxl {
        max-width: 220px;
      }
      @include media-3xl {
        font-size: 54px;
      }
      @include media-4xl {
        font-size: 64px;
        line-height: 40px;
        margin-bottom: 39px;
      }
      &:before {
        content: "";
        position: absolute;
        top: -55px;
        left: 0;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        border-radius: 50%;
        border: 1px solid #246EB9;
        opacity: 0.2;
        transform: scale(1.3);
        pointer-events: none;
        @include media-sm {
          transform: scale(1.2);
        }
        @include media-md {
          top: -45px;
          transform: scale(1.7);
        }
        @include media-md {
          transform: scale(1.5);
        }
        @include media-lg {
          transform: scale(1.4);
        }
        @include media-xl {
          transform: scale(1.7);
        }
        @include media-xxl {
          top: -10px;
          transform: scale(2.6);
        }
        @include media-3xl {
          top: -27px;
          transform: scale(2.3);
        }
        @include media-4xl {
          top: -28px;
          transform: scale(2.8);
        }
      }
    }
    &__text {
      max-width: 250px;
      margin: 0 auto;
      font-size: 16px;
      @include media-xxl {
        font-size: 14px;
      }
      @include media-3xl {
        font-size: 19px;
      }
      @include media-4xl {
        font-size: 20px;
      }
    }
    &:nth-child(4n+1) {
      .packages-step__name {
        color: #246EB9;
      }
    }
    &:nth-child(4n+2) {
      .packages-step__name {
        color: #2EA91B;
      }
    }
    &:nth-child(4n+3) {
      .packages-step__name {
        color: #FF4621;
      }
    }
    &:nth-child(4n+4) {
      .packages-step__name {
        color: #92779A;
      }
    }
  }
  .divider {
    bottom: 80px;
    transform: translate(-50%, 0) rotate(-8.46deg);
    @include media-3xl {
      bottom: 60px;
    }
    @include media-4xl {
      bottom: 80px;
    }
  }
  .no-container-mob {
    max-width: none;
    padding: 0;
    @include media-sm {
      max-width: none;
    }
    @include media-lg {
      max-width: 960px;
    }
    @include media-xl {
      max-width: 1200px;
    }
    @include media-xxl {
      max-width: 1400px;
    }
    @include media-3xl {

    }
    @include media-4xl {
      max-width: 1660px;
    }
  }
}