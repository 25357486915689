.header {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
  z-index: 4;
  @include media-xxl {
    padding: 14px 0;
  }
  @include media-3xl {
    padding: 18px 0;
  }
  @include media-4xl {
    padding: 28px 0;
  }
  .icon {
    width: 24px;
    height: 28px;
    margin-right: 12px;
    fill: #1F1F1F;
    @include media-xl {
      width: 14px;
      height: 17px;
    }
    @include media-3xl {

    }
    @include media-4xl {
      width: 24px;
      height: 28px;
    }
  }
  &-logo {
    line-height: 1;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    max-width: 166px;
    color: #1F1F1F;
  }
  &__contact {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 16px;
    font-weight: 400;
    line-height: 34px;
    @include media-lg {
      font-size: 18px;
      line-height: 1.2;
    }
    @include media-xl {
      font-size: 16px;
      line-height: 1.2;
    }
    @include media-3xl {
      font-size: 19px;
    }
    @include media-4xl {
      font-size: 22px;
      line-height: 34px;
    }
  }
  &__adress {
    display: none;
    @include media-lg {
      color: $text;
      max-width: 260px;
      display: flex;
      align-items: center;
    }
    @include media-xl {
      max-width: 290px;
    }
    @include media-xxl {
      max-width: none;
    }
    &:hover {
      color: $accent;
    }
  }
  &__phone {
    display: none;
    @include media-lg {
      display: flex;
      align-items: center;
      color: $text;
      margin: 0 10px;
      font-size: 18px;
      font-weight: 700;
      line-height: 1.2;
      letter-spacing: 1.1px;
    }
    @include media-xl {
      margin: 0 36px;
      font-size: 16px;
      line-height: 20px;
    }
    @include media-3xl {
      margin: 0 42px;
      font-size: 18px;
    }
    @include media-4xl {
      margin: 0 51px;
      font-size: 22px;
      line-height: 24px;

    }
    &:hover {
      color: $accent;
    }
  }
  &__btn {
    font-size: 11px;
    line-height: 1.2;
    padding: 9px 6px;

    @include media-lg {
      font-size: 18px;
      padding: 10px;
    }
    @include media-xxl {
      font-size: 15px;
      padding: 10px 15px;
    }
    @include media-3xl {
      font-size: 17px;
      padding: 10px 18px;
    }
    @include media-4xl {
      font-size: 20px;
      padding: 10px 22px;
    }
  }
}
.logo {
  img {
    height: auto;
    width: 100%;
    max-width: 130px;
    @include media-lg {
      max-width: 150px;
    }
    @include media-xl {
      max-width: 170px;
    }
    @include media-xxl {
      max-width: 190px;
    }
    @include media-4xl {
      max-width: 230px;
    }
  }
}