.modal {
  max-height: 100vh;
  overflow: auto;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 100;
  background: rgba(31, 31, 31, 0.30);
  justify-content: center;
  &__wrap {
    width: 100%;
    max-width: 95%;
    position: relative;
    margin: auto;
    @include media-sm {
      max-width: 580px;
    }
    @include media-lg {
      max-width: 775px;
    }
    @include media-xxl {
      max-width: 885px;
    }
  }
  &__body {
    padding: 45px 25px 25px;
    position: relative;
    border-radius: 47px;
    border: 1px solid #1F1F1F;
    background: #FFF;
    @include media-lg {
      padding: 40px;
    }
    @include media-xxl {
      padding: 113px 140px;
    }
  }
  &__title {
    color: #1F1F1F;
    text-align: center;
    font-weight: 700;
    font-size: 22px;
    line-height: 1.4;
    text-transform: uppercase;
    margin-bottom: 30px;
    @include media-lg {
      font-size: 28px;
      line-height: 40px;
      margin-bottom: 50px;
    }
    .accent-color {
      padding: 0 10px;
    }
  }
  &__input {
    font-family: "Cabin", Arial, Helvetica, sans-serif;
    border: none;
    border-bottom: 1px solid #000;
    width: 100%;
    color: #1F1F1F;
    font-size: 24px;
    font-weight: 500;
    line-height: 40px;
    text-transform: uppercase;
    outline: none;
    &::placeholder {
      font-family: "Cabin", Arial, Helvetica, sans-serif;
      color: #585858;

    }
  }
  &__btn {
    text-align: center;
    margin-top: 60px;
    @include media-lg {
      margin-top: 90px;
    }
    .btn {
      padding: 15px 13px;
      height: 60px;
      @include media-lg {
        padding: 30px 35px;
        height: 84px;
      }
    }
  }
}
.close {
  position: absolute;
  right: 35px;
  top: 22px;
  width: 22px;
  height: 22px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  @include media-lg {
    top: 25px;
    width: 25px;
    height: 25px;
  }
  @include media-3xl {
    top: 33px;
    width: 32px;
    height: 32px;
  }
  svg {
    fill: #303030;
    width: 22px;
    height: 22px;
    transition: 0.3s;
    @include media-lg {
      width: 35px;
      height: 35px;
    }
    @include media-3xl {
      width: 32px;
      height: 32px;

    }
  }
  &:hover {
      svg {
        fill: #000;
        transform: rotate(90deg);
      }
  }
}
  
.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
}
.disabled {
    display: none !important;
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}
  
.fadeOutUp {
  animation-name: fadeOutUp;
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}
#modalSupport {
  .modal__title {
    font-size: 25px;
    letter-spacing: 2px;
    @include media-lg {
      font-size: 39px;
    }
  }
}
#policy {
  .modal__wrap {
    text-align: left;
    max-width: 94%;
    @include media-lg {
      max-width: 80%;
    }
  }
  .modal {
    &__body {
      padding: 5%;
    }
    &__maintitle {
      font-size: 24px;
      line-height: 1.2;
      text-align: center;
      margin-bottom: 22px;
      color: #246EB9;
      font-weight: 700;
      word-break: break-word;
    }
    &__mainsubtitle {
      font-size: 22px;
      line-height: 1.2;
      text-align: center;
      margin-bottom: 20px;
      margin-top: 40px;
      font-weight: 500;
    }
  }
}
.policy__body {
  padding-top: 50px;
}