.btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 100%;
  padding: 10px 20px;
  border-radius: 50px;
  background: #1F1F1F;
  box-shadow: 0px 10px 20px 0px rgba(0, 127, 255, 0.50);
	text-align: center;
	cursor: pointer;
	border: none;
	outline: none;
	transition: color ease-out 120ms, background ease-out 120ms;
	color: #ffffff;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  @include media-sm {
    padding: 17px 20px;
    font-size: 21px;
  }
  @include media-lg {
    width: auto;
  }
  @include media-xxl {
    font-size: 16px;
    line-height: 24px;
    padding: 9px 17px;
  }
  @include media-3xl {
    font-size: 17px;
    padding: 14px 25px;
  }
  @include media-4xl {
    font-size: 20px;
    line-height: 24px;
    padding: 15px 27px;
  }
	&:hover,
  &:active  {
		background: #007DFB;
    box-shadow: 0px 10px 20px 0px rgba(188, 65, 255, 0.50);
	}
}
// .link {
//   display: flex;
//   align-items: center;
//   margin-right: auto;
//   font-size: 16px;
//   line-height: 24px;
//   column-gap: 8px;
//   color: $grey;
//   .icon {
//     padding: 3px;
//     width: 20px;
//     height: 20px;
//     fill: $tooltip;
//   }
//   &:hover {
//     color: $danger;
//     .icon {
//       fill: $danger;
//     }
//   }
// }